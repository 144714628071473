import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from 'react';

export const query = graphql`
  {
    homeModules: allContentfulContentBlock(
      filter: { tag: { eq: "home-module" } }
    ) {
      nodes {
        id
        body {
          childMarkdownRemark {
            html
          }
        }
        media {
          fluid(maxWidth: 256) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const Modules = () => {
  const { homeModules } = useStaticQuery(query);
  return (
    <div className="home-hero py-12 bg-white bg-gray-100">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mt-10">
          <ul className="md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
            {homeModules.nodes.map((item) => (
              <li key={item.id}>
                <div className="">
                  <div className="mx-auto md:w-3/4 lg:1/2">
                    <Img
                      className="rounded-lg"
                      fluid={item.media.fluid}
                    />
                    <div
                      className="mt-2 sm:mb-10 text-sm leading-6"
                      dangerouslySetInnerHTML={{
                        __html: item.body.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Modules
