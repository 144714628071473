import React from 'react';

const VideoModule = () => {
  return (
    <div className="text-center py-20">
      <div className="p-10 md:p-0 mx-auto md:w-2/3">
        <div className="lg:text-center">
          {/* <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Transactions</p> */}
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            A Family Legacy
        </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            The Emigh family has been farming and raising livestock for over 140 years.
        </p>
        </div>
        <div className='embed-container'><iframe src='https://www.youtube.com/embed/TtypEt8z5aA' frameBorder='0' allowFullScreen></iframe></div>
      </div>
    </div>
  )
}

export default VideoModule
