import React from "react";
import Hero from "../components/home/hero";
import HomeModules from '../components/home/modules';
import Video from '../components/home/video';
import Layout from "../components/layout";
import SEO from "../components/seo";


function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `livestock`,
          `breeding`,
          `grazing`,
          `feedlot`,
          `lambs`,
          `cattle`,
          `agriculture`,
        ]}
        title="Home"
      />
      <Hero />
      <HomeModules />
      <Video />
    </Layout>
  );
}

export default IndexPage;
