import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroImages {
      image: file(relativePath: { eq: "home-banner2.jpg" }) {
        id
        childImageSharp {
          fixed {
            base64
            width
            height
            src
            srcSet
          }
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  const imageData = data.image.childImageSharp.fluid;

  return (
    <BackgroundImage
      className="py-20"
      Tag="section"
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
      <div className="container items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
        <h2 className="text-4xl font-bold mb-2 text-white">
          Emigh Livestock
        </h2>
        <h3 className="text-2xl mb-8 text-gray-200">
          Building a Sustainable Future
        </h3>
      </div>
    </BackgroundImage>
  );
};

export default Hero;
